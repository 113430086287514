import { Form } from "react-bootstrap";
import styled from "styled-components";

export const LabelForm = styled(Form.Label)`
    font-weight: bold;
    display: block;
    color: ${({theme}) => theme.black};
    margin-bottom: 1rem;
`;

export const LabelRegular = styled(Form.Label)`
    font-weight: normal;
    color: ${({theme}) => theme.black};
    margin-bottom: 1rem;
`;
