import React from "react";
import { useSelector } from "react-redux";
import store from "../../store";

import {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "./styles";

function Modal({ BlockingModal }) {
  const { settings } = useSelector((state) => state);

  const { modalAlert } = settings;

  return (
    <ModalContainer>
      <ModalContent>
        <ModalHeader>{modalAlert.title}</ModalHeader>

        <ModalBody>{modalAlert.description}</ModalBody>
        {!BlockingModal && (
          <ModalFooter>
            <button
              onClick={() =>
                store.dispatch({
                  type: "SETTINGS",
                  payload: { modalAlert: { active: false } },
                })
              }
            >
              Fechar
            </button>
          </ModalFooter>
        )}
      </ModalContent>
    </ModalContainer>
  );
}

export default Modal;
