import React from 'react';
import { Container } from 'react-bootstrap';
import logoAcf from 'assets/images/logo-acf2.svg';
import imgAbout from 'assets/images/imgAbout.png';
import icoAcf from 'assets/images/icoAcf.svg';
import { Section, IcoDiv } from './styles';

const Resume = (props) => {

  return (
  <Section id="about" className="px-3">
    <Container>
      <div className="row">
        <div className="col-10">
          <div className="d-flex mb-3" style={{boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)', borderRadius: '90px', width: '10rem', height: '3rem', margin: '0 0 0 auto'}}> 
            <img className="img-fluid d-block m-auto" alt="Acordo Fechado" src={logoAcf} />
          </div>
          <h4> {props.title.substring(0,9)} <strong>{props.title.substring(9)}</strong></h4>
        </div>
        <div className="col-2">
          <img alt="Acordo Fechado" src={imgAbout}/>
        </div>
      </div>
      {props.text.map((e, index) =>(
        <p key={index}>{e}</p>
      ))}
      <IcoDiv>
        <img alt="Acordo fechado" className="img-fluid d-block m-auto" src={icoAcf} />
      </IcoDiv>
    </Container>
  </Section>
  );
}

export default Resume;