import {object, string} from "yup";

export const validator = object({
    dealStatus: string().required('Selecione uma opção').nullable(),
  }).required();

export const validatorInputs = object({
    dealStatus: string().required('Selecione uma opção').nullable(),
    email: string().email('Email invalido').required('Informe o seu email').nullable(),
    telephones: string().required('Informe o seu número de telefone').nullable()
  }).required();