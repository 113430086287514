export const theme = {
    primary: '#1781AA',
    background: '#E5E5E5',
    backgroundFooter: '#F2FBFF',
    backgroundLoader: '#0000008f',
    white: '#fff',
    error: '#FF3C3C',
    black: '#000000',
    text: '#545454',
    subTitle: '#9EA1A1',
    bgIconStep: '#C4C4C4',
}