import React, { useState, useEffect } from "react";
import { createBrowserHistory } from "history";
import { useSelector } from "react-redux";
import Header from "./components/header";
import Doubt from "./components/doubt";
import Resume from "./components/resume";
import Footer from "./components/footer";
import ButtonWhatsApp from "./components/whatsapp";
import Modal from "./components/Modal";
import Load from "./components/Load";
import AcordoFechado from "./components/AcordoFechado";

import { cases, deals, profile } from "./api/api";

import store from "./store";
import LoadingCase from "./components/LoadingCase";
import Form from "./pages/_form";
import { useProfile } from "./hooks/ProfileContext";
import { useBootRule } from "./hooks/BootRuleContext";
import resumeData from "assets/resumeData.json";

function App() {
  const hist = createBrowserHistory();
  const [, hash] = hist.location.search.split("?");
  const [, typeForm] = hist.location.pathname.split("/");
  const [statusAcordo, setStatusAcordo] = useState(false);

  let titlePage =
    typeForm === "interest"
      ? "Interesse no acordo"
      : typeForm === "proposal" && "Proposta de acordo";

  document.title = `Acordo Fechado | ${titlePage}`;

  const { settings } = useSelector((state) => state);
  const { fetchIsBlockedForm, toggleLoading } = useBootRule();
  const { setWhatsappNumber } = useProfile();

  useEffect(() => {
    (async () => {
      const responseProfile = await profile.find();
      if (responseProfile.data) {
        const whatsapp =
          responseProfile.data[0].whatsappNumbers.length > 0
            ? responseProfile.data[0].whatsappNumbers[0]
            : "";
        setWhatsappNumber(whatsapp);
      }
    })();
  }, [setWhatsappNumber]);

  useEffect(() => {
    typeForm && store.dispatch({ type: "TYPE_FORM", payload: { typeForm } });
  }, [typeForm]);

  useEffect(() => {
    fetchIsBlockedForm(hash);
    try {
      cases
        .find(hash)
        .catch((error) => {
          toggleLoading(false);
          console.log(error);
        })
        .then((res) => {
          if (res && res.data.internalId) {
            console.log(res.data)
            deals.find(`?id=${res.data.dealId}`).then((res) => {
              if (res && res.data.length > 0) {
                setStatusAcordo(res.data[0]);
                store.dispatch({
                  type: "SET_HASH",
                  payload: {
                    paymentType: res.data[0].paymentType,
                    proposals: res.data[0].proposals,
                  },
                });

                store.dispatch({
                  type: "FORM_PROPOSAL",
                  payload: {
                    authorCounterProposalText:
                      res.data[0].authorCounterProposalText,
                    authorCounterProposalValue:
                      res.data[0].authorCounterProposalValue,
                    dealId: res.data[0].id,
                    dealStatus: res.data[0].dealStatus,
                    lastProposal: res.data[0].lastProposal,
                    selectedPaymentType: res.data[0].selectedPaymentType,
                  },
                });
              }
            });

            store.dispatch({
              type: "SET_HASH",
              payload: {
                ...res.data,
                hash: res.data.internalId,
                hashValid: true,
                lawyerId: res.data.lawyerId,
              },
            });

            store.dispatch({
              type: "SETTINGS",
              payload: {
                autoSendDraft: res.data.autoSendDraft,
                sendToAdvocart: res.data.sendToAdvocart,
              },
            });
          } else {
            setStatusAcordo("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, [fetchIsBlockedForm, hash, toggleLoading]);

  const renderForm = () => {
    if (
      statusAcordo !== "error" &&
      statusAcordo.dealStatus.indexOf("Acordo Fechado") !== -1
    ) {
      return <AcordoFechado />;
    } else {
      switch (typeForm) {
        case "interest":
          return <Form typeForm={typeForm} />;
        case "proposal":
          return <Form typeForm={typeForm} />;
        default:
          break;
      }
    }
  };

  return (
    resumeData !== undefined && (
      <div className="App">
        <Header {...resumeData.main} />

        {statusAcordo ? renderForm() : <LoadingCase />}

        <Doubt id="help" {...resumeData.doubt} />
        <Resume id="about" {...resumeData.resume} />
        <ButtonWhatsApp {...resumeData.whatsApp} />

        <Footer {...resumeData.footer} />

        {settings.modalAlert.active && <Modal />}
        {settings.loading && <Load />}
      </div>
    )
  );
}

export default App;
