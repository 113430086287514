import React from 'react';
import { Favorite } from '@material-ui/icons'
import logoAcf from 'assets/images/logo-acf.svg'
import faceFooter from 'assets/images/face-footer.svg';
import linkedin from 'assets/images/linkdin-footer.svg';
import insta from 'assets/images/insta-footer.svg';
import { Section, Text2 } from './styles';

const Footer = (props) => {
  
  const socialMedia = [
    {
      "name":"face-footer.svg",
      "url":"https://www.facebook.com/acordofechadoonline",
      "src": faceFooter
    },
    {
      "name":"linkdin-footer.svg",
      "url":"https://www.linkedin.com/company/10417336/",
      "src": linkedin
    },
    {
      "name":"insta-footer.svg",
      "url":"https://www.instagram.com/acordofechado/",
      "src": insta
    }
  ]

  return (
    <Section  className="d-flex flex-column flex-sm-row">
      <img alt={props.logo} className="img-fluid d-none d-sm-block" src={logoAcf}/>
      <p className="text-center">{props.text}</p>
      <div className="my-3 my-sm-0 d-flex">
        {socialMedia.map((social,index) => (
          <a key={index} href={social.url}>
            <img 
              className="img-fluid mr-3" 
              alt={social.name} 
              src={social.src}/>
          </a>
        ))}
      </div>
      <div>
      <Text2>{props.text2.substring(0,9)} <Favorite style={{color: 'red'}} /> <Favorite style={{color: 'red'}} /> </Text2>
      <Text2>{props.text2.substring(9)}</Text2>

      </div>
    </Section>
  );
}

export default Footer;