import React from 'react';
import Step1 from './steps/step_1';
import Step2 from './steps/step_2';
import { useSelector } from 'react-redux';

// import { Container } from './styles';

function Interest() {
  const state = useSelector(state => state);
  
  const getStep = {
    0: () => <Step1 />,
    1: () => <Step2 />,
  }

  return (
    getStep[state.stepForm] && getStep[state.stepForm]()
  );
}

export default Interest;