import React, { createContext, useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { cases, deals } from "../api/api";

import Modal from "../components/Modal";
import store from "../store";
import { useProfile } from "./ProfileContext";

export const BootRuleContext = createContext();

export function useBootRule() {
  return useContext(BootRuleContext);
}

export function BootRuleProvider({ children }) {
  const { whatsappNumber } = useProfile();
  const [hash, setHash] = useState("");
  const [blockedForm, setBlockedForm] = useState(false);
  const maintenance = false;

  const redirectToMaintenance = useCallback((redirect) => {
    if ((typeof window !== "undefined" && maintenance) || redirect) {
      window.location.href = 'https://storage.googleapis.com/notifications-systems/index.html';
    }
  },[maintenance])
  

  useEffect(() => {
    fetch('https://storage.googleapis.com/notifications-systems/maintenance.json')
    .then(response => response.json())
    .then((response) => redirectToMaintenance(response?.active))
    .catch(error => redirectToMaintenance())
  },[redirectToMaintenance])

  const toggleLoading = (value) => {
    store.dispatch({
      type: "SETTINGS",
      payload: {
        loading: value,
      },
    });
  };

  const isBlockedForm = (data) => {
    const dealsStatus = data.dealStatus;

    if (
      dealsStatus.startsWith("Caso devolvido") ||
      ["Acordo Fechado - Houve desistência do escritório"].includes(dealsStatus)
    ) {
      handleModalBlockForm();
      return true;
    }
  };

  const handleModalBlockForm = () => {
    setBlockedForm(true);
    store.dispatch({
      type: "SETTINGS",
      payload: {
        modalAlert: {
          active: false,
          title: "Negociação Encerrada",
          description: `Esse processo não está mais apto para acordo, o cliente reavaliou seu caso por questões processuais. Maiores dúvidas entre em contato pelo número ${whatsappNumber}`,
        },
      },
    });

    return true;
  };

  const fetchDeal = (dealId) => {
    deals.find(`?id=${dealId}`).then((res) => {
      
      isBlockedForm(res.data[0]);

      toggleLoading(false);
    });
  };

  const fetchCase = async (hash) => {
    if (whatsappNumber) {
      toggleLoading(true);

      cases
        .find(hash)
        .then((res) => {
          if (!res) toggleLoading(false);
          if (res && res.data.internalId) {
            fetchDeal(res.data.dealId);
          }
        })
        .catch((erro) => toggleLoading(false));
    }
  };
  const fetchIsBlockedForm = (hash) => {
    setHash(hash);
    fetchCase(hash);
  };

  return (
    <BootRuleContext.Provider
      value={{ fetchIsBlockedForm, hash, toggleLoading, isBlockedForm }}
    >
      {children}
      {blockedForm && <Modal BlockingModal={blockedForm} />}
    </BootRuleContext.Provider>
  );
}
