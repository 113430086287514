import React, { createContext, useContext, useState } from "react";

export const ProfileContext = createContext();

export function useProfile() {
  return useContext(ProfileContext);
}

export function ProfileProvider({ children }) {
  const [whatsappNumber, setWhatsappNumber] = useState("");

  return (
    <ProfileContext.Provider value={{ whatsappNumber, setWhatsappNumber }}>
      {children}
    </ProfileContext.Provider>
  );
}
