import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    display: flex;

    z-index: 3;
    
    background: ${({theme}) => theme.backgroundLoader};

    height: 100vh;
    width: 100vw;
`;

export const ModalContent = styled.div`
     display: flex;
    flex-direction: column;
    margin: auto;

    background: ${({theme}) => theme.white};
    border-radius: 4px;

    width: 500px;
`;

export const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    
    padding-left: 15px;

    height: 60px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
`;

export const ModalBody = styled.div`

    padding: 15px;

`;

export const ModalFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 60px;
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-top: auto;

    button {
        background: ${({theme}) => theme.primary};
        color: ${({theme}) => theme.white};
        
        height: 43px;
        width: 120px;

        border: none;
        border-radius: 4px;

        &:hover {
            opacity: 0.8;
        }
    }

`;