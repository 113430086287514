import { FiberManualRecord } from "@material-ui/icons";
import styled from "styled-components";

export const Title = styled.h2`
  color: ${({theme}) => theme.black};
  font-size: 1rem;
  font-weight: bold;
`;

export const SubText = styled.p`
  color: ${({theme}) => theme.black};
  font-size: 1rem;
`;

export const DivSteps = styled.div`
  display: flex;
`;

export const IconStep = styled(FiberManualRecord)`
  color: ${({show, theme}) => show ? theme.primary : theme.bgIconStep};
`;

export const Button = styled.button`
  background: ${props => props.disabled ? 'gray' : `${props.background}`} !important;
  color: ${props => `${props.color}`} !important;
  border: none;
 
  width: ${props => props.width ? `${props.width}`: '165px'};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
 
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  margin-right: 1rem;
`;