import React from 'react';
import { useForm, Controller } from "react-hook-form";
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, InputCustom } from '../../../../../components/Atoms/input/styles';
import { LabelForm } from '../../../../../components/Atoms/LabelForm/styles';
import { Button } from '../styles';
import { Title } from './styles';
import { validator } from './validator';
import { Error } from '../../../../../components/Atoms/ErrorForm/styles';
import { useProposalFormStep2 } from '../../hooks/useProposalFormStep2';

function Index() {
  const { dataDeals } = useSelector(state => state);
  const caseType = dataDeals.group?.caseType;

  const { register, handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(validator)
  });

  const dispatch = useDispatch();

  const {
    useActiveCasesCounterproposal,
    usePassiveCasesCounterproposal,
    handleBackStep,
    loading,
  } = useProposalFormStep2();

  const caseTypeLiterals = {
    PASSIVO: usePassiveCasesCounterproposal,
    ATIVO: useActiveCasesCounterproposal
  }

  const onSubmitt = async data => {
    dispatch({ type: 'SETTINGS', payload: { loading: true } });
    
    if (typeof caseTypeLiterals[caseType] === 'function') {
      caseTypeLiterals[caseType](data)
    }
  };

  return (
    <div className="p-5">
      <Title className="text-left">Contraproposta</Title>

      <form onSubmit={handleSubmit(onSubmitt)}>
        <Form.Group as={Col} className={`px-0 pb-0`}>
          <LabelForm>Qual o valor da contraproposta?</LabelForm>

          <Controller
            {...register('authorCounterProposalValue')}
            render={({ field }) => (
              <InputCustom
                prefix='R$ '
                decimalScale={2}
                allowNegative={false}
                fixedDecimalScale
                placeholder="R$ 100,00"
                decimalSeparator=","
                thousandSeparator="."
                {...field} />
            )}
            name="authorCounterProposalValue"
            className="input"
            control={control}
          />

          <Error>{errors.authorCounterProposalValue?.message}</Error>
        </Form.Group>

        <Form.Group as={Col} className={`px-0 pb-0`}>
          <InputGroup className="mb-3">
            <LabelForm>Há o desejo da inclusão de eventual obrigação de fazer, qual seria? OBS: Caso não tenha obrigação de fazer, favor deixar em branco</LabelForm>
            <Input
              {...register('authorCounterProposalText')}
              type="text"
              placeholder="Descreva..."
              name="authorCounterProposalText" />
            <Error>{errors.authorCounterProposalText?.message}</Error>
          </InputGroup>
        </Form.Group>

        <Button
          disabled={loading}
          onClick={handleBackStep}
          background="#808080"
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3">Voltar</Button>

        <Button
          disabled={loading}
          background="#004D7D"
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3">Continuar</Button>
      </form>
    </div>
  );
}

export default Index;