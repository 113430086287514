import React from "react";
import { Link } from "@material-ui/core";
import { WhatsDesk, WhatsMobile } from "./styles";
import btWhatsDesk from 'assets/images/btWhatsDesk.svg'
import btWhatsMobile from 'assets/images/btWhatsMobile.svg'
import { useProfile } from "../../hooks/ProfileContext";

function Whatsapp(props) {
  const { whatsappNumber } = useProfile();
  
  const numberWhatsapp = props.link.replace("{number}", whatsappNumber);

  return (
    <Link href={numberWhatsapp} target="_blank">
      <WhatsDesk
        alt="Whats"
        className="img-fluid"
        src={btWhatsDesk}
      />
      <WhatsMobile
        alt="Whats"
        className="img-fluid"
        src={btWhatsMobile}
      />
    </Link>
  );
}

export default Whatsapp;
