import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Input,
  InputMaskCustom,
} from "../../../../../components/Atoms/input/styles";
import { LabelForm } from "../../../../../components/Atoms/LabelForm/styles";
import { Button } from "../styles";
import { Title } from "./styles";
import { validator } from "./validator";
import { persons, deals } from "../../../../../api/api";
import { Error } from "../../../../../components/Atoms/ErrorForm/styles";

function Index() {
  const { dataDeals, stepFormOld, formProposal, settings } = useSelector(
    (state) => state
  );
  const { loading } = settings;

  let { paymentType, lawyerId } = dataDeals;
  paymentType = paymentType.replace(/ /g, "_");
  paymentType = paymentType.toLowerCase();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  const dispatch = useDispatch();

  const getRadioInput = (inputs) => {
    if (inputs.length > 0) {
      return inputs.map((input) => (
        <Form.Check
          {...register("paymentType")}
          className="mb-3"
          key={input.label}
          id={input.label}
          sm={12}
          type="radio"
          label={input.label}
          value={input.value}
          name={"paymentType"}
        />
      ));
    }
  };

  const renderInputRadio = {
    depósito_na_conta_do_autor_ou_depósito_na_conta_do_advogado_ou_id_depósito:
      () => {
        return getRadioInput([
          {
            label: "Depósito na Conta do Autor",
            value: "Depósito na Conta do Autor",
          },
          {
            label: "Depósito na Conta do Advogado",
            value: "Depósito na Conta do Advogado",
          },
          { label: "ID depósito Judicial", value: "ID Depósito" },
        ]);
      },
    id_depósito: () => {
      return getRadioInput([
        { label: "ID depósito Judicial", value: "ID Depósito" },
      ]);
    },
    depósito_na_conta_do_autor_ou_id_depósito: () => {
      return getRadioInput([
        {
          label: "Depósito na Conta do Autor",
          value: "Depósito na Conta do Autor",
        },
        { label: "ID depósito Judicial", value: "ID Depósito" },
      ]);
    },
    depósito_na_conta_do_autor: () => {
      return getRadioInput([
        {
          label: "Depósito na Conta do Autor",
          value: "Depósito na Conta do Autor",
        },
      ]);
    },
    voucher: () => {
      return getRadioInput([
        {
          label: "Voucher",
          value: "Voucher",
        },
      ]);
    },
    créditos: () => {
      return getRadioInput([
        {
          label: "Créditos",
          value: "Créditos",
        },
      ]);
    },
    boleto_bancário: () => {
      return getRadioInput([
        {
          label: "Boleto Bancário",
          value: "Boleto Bancário",
        },
      ]);
    },
    ted_ou_doc: () => {
      return getRadioInput([
        { label: "TED", value: "Ted" },
        { label: "DOC", value: "Doc" },
      ]);
    },
    depósito_na_conta_do_autor_ou_depósito_na_conta_do_advogado: () => {
      return getRadioInput([
        {
          label: "Depósito na Conta do Autor",
          value: "Depósito na Conta do Autor",
        },
        {
          label: "Depósito na Conta do Advogado",
          value: "Depósito na Conta do Advogado",
        },
      ]);
    }
  };

  const backstep = () => {
    dispatch({
      type: 'FORM_PROPOSAL',
      payload: {
        ...formProposal,
        'dealStatus':  formProposal.oldDealStatus,
        'status': formProposal.oldStatus,
      }
    })
    dispatch({ type: "STEP_FORM", payload: { stepForm: stepFormOld } });
  };

  const nextStep = (step) => {
    dispatch({ type: "SETTINGS", payload: { loading: false } });
    dispatch({
      type: "STEP_FORM",
      payload: { stepFormOld: 3, stepForm: step },
    });
  };

  const onSubmit = async (data) => {
    dispatch({ type: "SETTINGS", payload: { loading: true } });

    const contactsPerson = Object.assign({}, data);
    delete contactsPerson.paymentType;

    function formatContactsPersonToUpdate(contactsPerson) {
      let hotPhone = Object.assign({}, contactsPerson);
      hotPhone = hotPhone.telephones;
      delete contactsPerson.telephones;
      return {
        ...contactsPerson,
        hotPhone: hotPhone,
        lawyerId,
      };
    }

    const payloadDeal = {
      ...formProposal,
      selectedPaymentType: data.paymentType,
    };

    let response = await persons.put(
      formatContactsPersonToUpdate(contactsPerson)
    );

    response = await deals.quickUpdate(payloadDeal);

    if (response) {
      nextStep(5);
    } else {
      dispatch({
        type: "SETTINGS",
        payload: {
          modalAlert: {
            active: true,
            title: "Erro",
            description: "Ocorreu um erro, entre em contato com o suporte",
          },
          loading: false,
        },
      });
    }
  };

  return (
    <div className="p-5">
      <Title className="text-left">
        Confirme seus dados de contato e forma de pagamento!
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Col} className={`px-0 pb-0`}>
          <LabelForm>Selecione a forma de pagamento para o caso</LabelForm>
          {renderInputRadio[paymentType] ? renderInputRadio[paymentType]() : ""}
          <Error>{errors.paymentType?.message}</Error>
        </Form.Group>

        <Form.Group as={Col} className={`px-0 pb-0`}>
          <InputGroup className="mb-3">
            <LabelForm>
              Favor confirmar seu e-mail abaixo para envio das demais etapas da
              negociação:
            </LabelForm>
            <Input
              {...register("email")}
              type="text"
              placeholder="email@dominio.com.br"
              name="email"
            />
          </InputGroup>
          <Error>{errors.email?.message}</Error>
        </Form.Group>

        <Form.Group as={Col} className={`px-0 pb-0`}>
          <InputGroup className="mb-3">
            <LabelForm>
              Favor confirmar seu telefone com whatsapp abaixo para envio das demais etapas
              da negociação:
            </LabelForm>
            <Controller
              {...register("telephones")}
              control={control}
              name="telephones"
              render={({ field }) => (
                <InputMaskCustom onChange={field.onChange} error={errors.telephones?.message} mask="(99) 99999-9999" />
              )}
            />
          </InputGroup>
          <Error>{errors.telephones?.message}</Error>
        </Form.Group>

        <Button
          disabled={loading}
          type="button"
          onClick={backstep}
          background={"#808080"}
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3"
        >
          Voltar
        </Button>

        <Button
          disabled={loading}
          type="submit"
          background="#004D7D"
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3"
        >
          Continuar
        </Button>
      </form>
    </div>
  );
}


export default Index;