import styled from 'styled-components';

export const Container = styled.div`
  max-width: 30rem;
  margin: auto;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  text-align: center;

  img {
      margin-bottom: 3rem;
  }

`;
