import React from 'react';
import imgSucess from 'assets/images/imgSucess.svg';
import faceFooter from 'assets/images/face-footer.svg';
import linkedin from 'assets/images/linkdin-footer.svg';
import insta from 'assets/images/insta-footer.svg';
import { FooterSucess, SubText, Title } from './styles';
import { useSelector } from 'react-redux';

function Index() {
  const {acceptProposal} = useSelector(
    (state) => state
  );
 const socialMedia = [
    {
      "name":"face-footer.svg",
      "url":"https://www.facebook.com/acordofechadoonline",
      "src": faceFooter
    },
    {
      "name":"linkdin-footer.svg",
      "url":"https://www.linkedin.com/company/10417336/",
      "src": linkedin
    },
    {
      "name":"insta-footer.svg",
      "url":"https://www.instagram.com/acordofechado/",
      "src": insta
    }
  ]


  const textAcceptProposal = acceptProposal ? 'seu aceite' : "sua contraproposta";
  return (
    <>
      <div className="p-5">
        <Title className="text-center">{"Obrigado por usar o Acordo Fechado!"}</Title>

        <img 
          alt={"success"} 
          src={imgSucess} 
          className="img-fluid d-block p-3 m-auto" />

        <SubText className="text-center">Recebemos {textAcceptProposal}. Agora seu caso será analisado pelo Réu, se ele aprovar, em até 10 dias úteis enviará a minuta para o email informado no preenchimento do formulário. Caso não seja aprovado, comunicaremos também via e-mail</SubText>
      </div>

      <FooterSucess className="p-3">
        <div className="d-flex justify-content-center align-items-center my-3">
          {socialMedia.map((img, index) => (
            <a key={index} href={img.url}>
              <img 
              className="img-fluid mr-3" 
              alt={img.name} 
              src={img.src} />
            </a>
          ))}
        </div>

        <p className="text-center">Siga nossas redes sociais e acompanhe as novidades do mercado jurídico 4.0</p>
      </FooterSucess>
    </>
  );
}

export default Index;