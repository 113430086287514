import React, { useRef, useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Scrollchor from 'react-scrollchor';
import logoAcf from 'assets/images/logo-acf.svg';
import faceFooter from 'assets/images/face-footer.svg';
import linkedin from 'assets/images/linkdin-footer.svg';
import insta from 'assets/images/insta-footer.svg';
import StyleDefault, { Sociais, Link, HeaderNav } from './styles';

const Header = (props) => {
  const [ isSticky, setSticky ] = useState(false)
  const element = useRef(null);

  const dispatch = useDispatch();

  const socialMedia = [
    {
      "name":"face-footer.svg",
      "url":"https://www.facebook.com/acordofechadoonline",
      "src": faceFooter
    },
    {
      "name":"linkdin-footer.svg",
      "url":"https://www.linkedin.com/company/10417336/",
      "src": linkedin
    },
    {
      "name":"insta-footer.svg",
      "url":"https://www.instagram.com/acordofechado/",
      "src": insta
    }
  ]

  const handleScroll = () => {
    if(element.current ){
      if(window.scrollY > element.current.getBoundingClientRect().height){
        setSticky(true) 
      }else{
        setSticky(false)
      }
      
    }else{
      setSticky(false)
    }
  }
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <HeaderNav ref={element} className={isSticky ? 'navbar-sticky px-xl-5' : 'px-xl-5'} expand="lg" style={{background: '#ffffff'}}>
      <Navbar.Brand href="#home"><img alt="Acordo fechado"  src={logoAcf}/></Navbar.Brand>
      <Navbar.Toggle style={{border: 'none'}} aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          {props.menus.map((e, index) => (
            e.label === 'Como funciona' ? 
            <Nav.Link className="text-center" key={index} onClick={() =>  dispatch({type: 'HOW_WORKS', payload:{modalHowWorks: true }})} href={e.link}>{e.label}</Nav.Link>
            : 
             <Scrollchor className="nav-link text-center" key={index} to={e.link}>{e.label}</Scrollchor>
          ))}
        </Nav>
        <Nav className="d-none d-sm-flex mr-xl-5">
          <Link className="nav-link" to={props.link.url}>{props.link.label}</Link>
        </Nav>
        <Sociais className="d-none d-xl-flex">
          {socialMedia.map((social) => (
            <Nav.Link 
              key={social.name} 
              href={social.url}>
                <img alt={social.name} src={social.src} />
            </Nav.Link>
          ))}
        </Sociais>
      </Navbar.Collapse>
      <StyleDefault />
    </HeaderNav>);
}

export default Header;