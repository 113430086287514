import React, { useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { LabelForm, LabelRegular } from '../../../../../components/Atoms/LabelForm/styles';
import { Input, InputMaskCustom } from '../../../../../components/Atoms/input/styles';
import { Error } from '../../../../../components/Atoms/ErrorForm/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Title } from './styles';
import { Button } from '../../../styles';
import { dealStatusOptions } from '../../../../../utils/dealStatus';
import { deals, persons } from '../../../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import {validator, validatorInputs} from './validator';

const Step1 = () => {
    const [showInputs, setShowInputs] = React.useState(false);
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        // setValue,
    } = useForm({
        resolver: yupResolver(showInputs ? validatorInputs : validator),
    });
    const dispatch = useDispatch();
    const { dataDeals } = useSelector(state => state);
    
    const [ loading, setLoading] = useState(false);

    const responseToObject = (response) => {
        return response.data.reduce((obj, item) => (obj[item.id] = item), {});
      };

    const fetchFindConstants = async (dealStatus) => {
        try {
            const response = await deals.findConstants(`?id=${dealStatus}`);
        
            return responseToObject(response);
            
        } catch (error) {
            console.log('fetchFindConstants.catch', error.message);
        }
      };

      function formatContactsPersonToUpdate(contactsPerson) {
        let hotPhone = Object.assign({}, contactsPerson);
        hotPhone = hotPhone.telephones;
        delete contactsPerson.telephones;
        return {
          ...contactsPerson,
          hotPhone: hotPhone,
          lawyerId: dataDeals.lawyerId,
        };
      }

    async function onSubmit(data) {
        dispatch({ type: "SETTINGS", payload: { loading: true } });
        setLoading(true);
    
        const response = await fetchFindConstants(data.dealStatus);
    
        if (response) {
            Promise.all([
                persons.put( formatContactsPersonToUpdate(data)), 
                deals.quickUpdate({
                    dealId: dataDeals.dealId,
                    dealStatus: response.dealStatus,
                    status: response.status,
                })
            ])
            .then(() => {
                setLoading(false)
                dispatch({ type: "SETTINGS", payload: { loading: false } });
                dispatch({
                    type: "STEP_FORM",
                    payload: { stepFormOld: 0, stepForm: 1 },
                  });
                dispatch({
                    type: 'FORM_INTEREST',
                    payload: {
                        isInterest: data.dealStatus === dealStatusOptions.temInteresseEmNegociar
                    }
                  });
            });
        }
        
    }

    return (
        <div className="p-5">
            <Title className="text-left">{"Proposta de Interesse"}</Title>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group as={Col} className={`px-0 pb-0`}>
                    <InputGroup className="mb-3 d-flex flex-column">
                        <LabelForm>Há interesse no Acordo? *</LabelForm>
                        <Form.Check
                            {...register("dealStatus")}
                            sm={12}
                            type="radio"
                            id="Sim, tenho interesse"
                            label="Sim, tenho interesse"
                            value={dealStatusOptions.temInteresseEmNegociar}
                            onChange={() => setShowInputs(true)}
                            name="dealStatus"
                        />
                    </InputGroup>
                    <InputGroup className="mb-3 d-flex flex-column">
                        <Form.Check
                            {...register("dealStatus")}
                            className="mb-3"
                            sm={12}
                            type="radio"
                            onChange={() => setShowInputs(false)}
                            id="Fazer contraproposta (ou outros motivos)"
                            label="Não tenho interesse"
                            name="dealStatus"
                            value={dealStatusOptions.semAcordoAusenciDeInteresse}
                        />
                        <Error>{errors.dealStatus?.message}</Error>
                    </InputGroup>
                </Form.Group>
                {showInputs && (
                    <>
                        <LabelRegular>
                            Que bom que você tem interesse no acordo!
                            Precisamos de alguns dados de contato atualizado para levantar uma proposta da empresa para lhe enviar em seguida.
                        </LabelRegular>
                        <Form.Group as={Col} className={`px-0 pb-0`}>
                            <InputGroup className="mb-3">
                                <LabelForm>
                                    Favor confirmar seu e-mail abaixo para envio das demais etapas da
                                    negociação:
                                </LabelForm>
                                <Input
                                    {...register("email")}
                                    type="text"
                                    placeholder="email@dominio.com.br"
                                    name="email"
                                />
                            </InputGroup>
                            <Error>{errors.email?.message}</Error>
                        </Form.Group>
                        <Form.Group as={Col} className={`px-0 pb-0`}>
                            <InputGroup className="mb-3">
                                <LabelForm>
                                    Favor confirmar seu telefone com whatsapp abaixo para envio das demais etapas
                                    da negociação:
                                </LabelForm>
                                <Controller
                                    {...register("telephones")}
                                    control={control}
                                    name="telephones"
                                    render={({ field }) => (
                                        <InputMaskCustom placeholder='(99) 99999-9999' onChange={field.onChange} error={errors.telephones?.message} mask="(99) 99999-9999" />
                                    )}
                                />
                            </InputGroup>
                            <Error>{errors.telephones?.message}</Error>
                        </Form.Group>
                    </>
                )}

                <Button
                    disabled={loading}
                    background="#004D7D"
                    color="#FFFFFF"
                    className="d-block order-1 w-100 mb-3"
                >
                    Continuar
                </Button>
            </form>
        </div>
    );
}

export default Step1;
