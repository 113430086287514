import styled from 'styled-components';
import { Container, Form } from 'react-bootstrap';

export const Section = styled.div`
  background-image: url(${props => `${props.background}`});
  background-color: ${({theme}) => theme.backgroundFooter};
  
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const CardForm = styled(Container)`
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  max-width: 30rem;
`;

export const Button = styled.button`
  background: ${props => props.disabled ? 'gray' : `${props.background}`} !important;
  color: ${props => `${props.color}`} !important;
  border: none;
  
  width: ${props => props.width ? `${props.width}`: '165px'};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  margin-right: 1rem;
`;

export const ButtonMobile = styled.button`
  background: transparent !important;
  color: ${({theme}) => theme.bgIconStep} !important;
  border: none;
  
  width: ${props => props.width ? `${props.width}`: '165px'};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
`;

export const Title = styled.h2`
  color: ${({theme}) => theme.black};
  font-size: 1rem;
  font-weight: bold;
`;

export const SubTitle = styled.span`
  color: ${({theme}) => theme.SubTitle};
  font-size: 0.8rem;
`;

export const SubText = styled.p`
  color: ${({theme}) => theme.black};
  font-size: 1rem;
`;

export const LabelForm = styled(Form.Label)`
  font-weight: bold;
  color: ${({theme}) => theme.black};
  margin-bottom: 1rem;
`;

export const TextForm = styled(Form.Label)`
  font-weight: normal;
  font-size: 0.8rem;
  text-align: justify;

  margin-bottom: 1rem;
  color: ${({theme}) => theme.black};
`;

export const InputsText = styled(Form.Control)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({error, theme}) => error === 'true' ? theme.error : theme.black}; 
`;

export const FooterSucess = styled.div`
  background: ${({theme}) => theme.backgroundFooter};
  border-radius: 4px;
`;