import axios from "axios";



// let BASE = "http://localhost:3001";
let BASE = process.env.REACT_APP_HOST;
  

const cases = {
  find: async function (payload) {
    try {
      const response = await axios.get(`${BASE}/api/adv/cases/${payload.replace('/','')}`);
      return response;
    } catch (error) {
      console.error(error.response);
    }
  },

  check: async function (payload) {
    try {
      const response = await axios.get(
        `${BASE}/api/adv/cases/check/${payload}`
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  },
};

const persons = {
  put: async function (payload) {

    try {
      const response = await axios.put(
        `${BASE}/api/adv/persons/${payload.lawyerId}/addContacts`,
        payload
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
};

const profile = {
  find: async function () {
    try {
      return await axios.get(`${BASE}/api/persons/profile?id=1577`);
    } catch (error) {
      console.log(error);
    }
  },
};

const deals = {
  find: async function (payload) {
    try {
      return await axios.get(`${BASE}/api/adv/deals` + payload);
    } catch (error) {}
  },

  update: async function (payload) {
    try {
      const response = await axios.put(
        `${BASE}/api/adv/deals/updateStatus`,
        payload
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  quickUpdate: async function (payload) {
    if (!payload.dealStatus) {
      delete payload.dealStatus;
      delete payload.status;
    }
    
    delete payload.oldDealStatus;
    delete payload.oldStatus;

    try {
      const response = await axios.put(
        `${BASE}/api/adv/deals/${payload.dealId}/quickUpdate`,
        {...payload,
        date:{
          origin: 'form-proposal'
        }}
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  findConstants: async function (payload) {
    return await axios.get(
      `${BASE}/api/constants/dealStatus${payload}`
    );
  },
};
export { cases, persons, deals, profile };
