import styled from 'styled-components';

export const Section = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: ${({theme}) => theme.white};
  h2{
    font-weight: 300;
    margin-bottom: 1.5rem;
    strong{
      font-weight: bold;
    }
  }
  p{
    color:  ${({theme}) => theme.text};
  }
`;

export const IcoDiv = styled.div`
  background: ${({theme}) => theme.backgroundFooter};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 98px;
  width: 67px;
  height: 38px;
  display: flex;
  margin: 0 0 0 auto;
`;