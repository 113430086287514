import { deals } from "api/api";
import { decimalToCalc } from "components/Mask";
import { useDispatch, useSelector } from "react-redux";
import { dealStatusOptionName, dealStatusOptions, whatStatus } from "utils/dealStatus";

export const useProposalFormStep2 = () => {
  const dispatch = useDispatch();

  const { stepFormOld, formProposal, settings, dataDeals  } = useSelector(state => state);
  const { autoSendDraft, loading, sendToAdvocart } = settings;

  const conterProposalGreaterMaximumProposal = ({ conterProposal, maximumProposal }) => {
    return decimalToCalc(conterProposal) > decimalToCalc(maximumProposal)
  };

  const counterProposalLesserThanMaxProposal = (counterProposal, maximumProposal) => {
    return decimalToCalc(counterProposal) < decimalToCalc(maximumProposal)
  };

  const handleBackStep = () => {
    dispatch({ type: 'STEP_FORM', payload: { stepForm: stepFormOld } })
  }

  const nextStep = (step) => {
    dispatch({ type: 'SETTINGS', payload: { loading: false } })
    dispatch({ type: 'STEP_FORM', payload: { stepFormOld: 1, stepForm: step } })
  }

  const getMaxProposal = (value) => {
    return value[0] ? value[value.length - 1] : '0,00';
  }

  const fetchDeals = async (dealId) => {
    const response = await deals.findConstants(`?id=${dealId}`);

    function responseToObject(response) {
      return response.data.reduce((obj, item) => obj[item.id] = item, {});
    }

    return responseToObject(response);
  }

  const usePassiveCasesCounterproposal = async (data) => {
    if (conterProposalGreaterMaximumProposal({ conterProposal: data.authorCounterProposalValue, maximumProposal: getMaxProposal(dataDeals.proposals) })) {
      const responseDealStatus = await fetchDeals(dealStatusOptions.feitoContrapropostapeloAutor);
      if (decimalToCalc(dataDeals.lastProposal) < decimalToCalc(getMaxProposal(dataDeals.proposals)) ) {
        const responseDealStatus = await fetchDeals(dealStatusOptions.feitoContrapropostapeloAutor);
        dispatch({
          type: 'FORM_PROPOSAL',
          payload: {
            ...formProposal,
            'status': responseDealStatus.status,
            'dealStatus': dealStatusOptionName.feitoContrapropostapeloAutor,
            'lastProposal': getMaxProposal(dataDeals.proposals).replace('R$ ', ''),
            'sendEmail': 'proposta_majorada',
            'authorCounterProposalValue': data.authorCounterProposalValue.replace('R$ ', ''),
            'authorCounterProposalText': data.authorCounterProposalText
          }
        });
      } else {
        dispatch({
          type: 'FORM_PROPOSAL',
          payload: {
            ...formProposal,
            'status': responseDealStatus.status,
            'dealStatus': dealStatusOptionName.feitoContrapropostapeloAutor,
            'authorCounterProposalValue': data.authorCounterProposalValue.replace('R$ ', ''),
            'authorCounterProposalText': data.authorCounterProposalText
          }
        });
      }

      nextStep(3)

    } else {
      const deal = whatStatus({autoSendDraft, sendToAdvocart});
      const responseDealStatus = await fetchDeals(deal);
      dispatch({
        type: 'FORM_PROPOSAL',
        payload: {
          ...formProposal,
          'status': responseDealStatus.status,
          'dealStatus': responseDealStatus.dealStatus,
          'lastProposal': data.authorCounterProposalValue.replace('R$ ', ''),
          'authorCounterProposalValue': data.authorCounterProposalValue.replace('R$ ', ''),
          'authorCounterProposalText': data.authorCounterProposalText
        }
      });

      nextStep(3)
    }
  }

  const useActiveCasesCounterproposal = async (data) => {

    if (counterProposalLesserThanMaxProposal(data.authorCounterProposalValue, getMaxProposal(dataDeals.proposals))) {
      const responseDealStatus = await fetchDeals(dealStatusOptions.feitoContrapropostapeloAutor);
      if (decimalToCalc(dataDeals.lastProposal) > decimalToCalc(getMaxProposal(dataDeals.proposals)) ) {
        const responseDealStatus = await fetchDeals(dealStatusOptions.feitoContrapropostapeloAutor);
        dispatch({
          type: 'FORM_PROPOSAL',
          payload: {
            ...formProposal,
            'status': responseDealStatus.status,
            'dealStatus': dealStatusOptionName.feitoContrapropostapeloAutor,
            'lastProposal': getMaxProposal(dataDeals.proposals).replace('R$ ', ''),
            'sendEmail': 'proposta_majorada',
            'authorCounterProposalValue': data.authorCounterProposalValue.replace('R$ ', ''),
            'authorCounterProposalText': data.authorCounterProposalText
          }
        });
      } else {
        dispatch({
          type: 'FORM_PROPOSAL',
          payload: {
            ...formProposal,
            'status': responseDealStatus.status,
            'dealStatus': dealStatusOptionName.feitoContrapropostapeloAutor,
            'authorCounterProposalValue': data.authorCounterProposalValue.replace('R$ ', ''),
            'authorCounterProposalText': data.authorCounterProposalText
          }
        });
      }

      nextStep(3)

    } else {
      const deal = whatStatus({autoSendDraft, sendToAdvocart});
      const responseDealStatus = await fetchDeals(deal);
      dispatch({
        type: 'FORM_PROPOSAL',
        payload: {
          ...formProposal,
          'status': responseDealStatus.status,
          'dealStatus': responseDealStatus.dealStatus,
          'lastProposal': data.authorCounterProposalValue.replace('R$ ', ''),
          'authorCounterProposalValue': data.authorCounterProposalValue.replace('R$ ', ''),
          'authorCounterProposalText': data.authorCounterProposalText
        }
      });

      nextStep(3)
    }
  };

  return {
    usePassiveCasesCounterproposal,
    useActiveCasesCounterproposal,
    handleBackStep,
    loading,
  }
};