import React, { useState } from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';
import { Section, CardAccordion} from './styles';
import { ChevronRight, ExpandMore } from '@material-ui/icons';

const Doubt = (props) => {
  const [ toggle, handleToggle ] = useState(0);
  return (
    <Section id="help">
    <Container>
      <div className="px-sm-5">
        <h2 className="d-none d-sm-block mb-3 text-center">{props.title}</h2>
        <h5 className="d-block d-sm-none mb-3 text-center">{props.title}</h5>
      </div>
      <Accordion defaultActiveKey={toggle}>
        {props.doubt.map((d, index) =>
          <CardAccordion className="mb-3" key={index}>
            <Accordion.Toggle as={Card.Header} className="d-flex justify-content-between" eventKey={index} onClick={() => handleToggle(index)}>
              {d.title} {index === toggle ? <ExpandMore /> : <ChevronRight /> } 
            </Accordion.Toggle>
            <Accordion.Collapse className={index === toggle ? 'show' : ''} eventKey={index}>
              <Card.Body>{d.text}</Card.Body>
            </Accordion.Collapse>
          </CardAccordion>
        )}
    </Accordion>
  </Container>
  </Section>);
}

export default Doubt;