import React, { useEffect } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { Input } from "../../../../../components/Atoms/input/styles";
import { LabelForm } from "../../../../../components/Atoms/LabelForm/styles";
import { Button } from "../styles";
import { Title } from "./styles";
import { validator } from "./validator";
import { deals, cases } from "../../../../../api/api";
import { Error } from "../../../../../components/Atoms/ErrorForm/styles";
import { useBootRule } from "../../../../../hooks/BootRuleContext";
import {  checkAcceptProposal, dealStatusOptions, whatStatus } from "../../../../../utils/dealStatus";

function Index() {
  const dispatch = useDispatch();
  const { isBlockedForm } = useBootRule();
  const { dataDeals, formProposal, settings } = useSelector((state) => state);
  const { autoSendDraft, loading, sendToAdvocart} = settings;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validator),
  });

  const handleLoading = (loading) =>
    dispatch({ type: "SETTINGS", payload: { loading } });

  useEffect(() => {
    setValue("codValidador", dataDeals.internalId);
  }, [dataDeals]); //eslint-disable-line

  const nextStep = (step) => {
    handleLoading(false);

    return dispatch({
      type: "STEP_FORM",
      payload: { stepFormOld: 0, stepForm: step },
    });
  };

  const responseToObject = (response) => {
    return response.data.reduce((obj, item) => (obj[item.id] = item), {});
  };

  const fetchFindConstants = async (dealStatus) => {
    const response = await deals.findConstants(`?id=${dealStatus}`);

    return responseToObject(response);
  };

  const onSubmit = async (data) => {
    handleLoading(true);
    
    let payloadSetHash = { ...dataDeals, hash: data.codValidador };
    let payloadFormProposal = formProposal;

    const responseCases = await cases.check(data.codValidador);

    if (responseCases.data?.dealId) {
      const responseDeals = await deals.find(
        `?id=${responseCases.data.dealId}`
      );
      if (isBlockedForm(responseDeals.data[0])) {
        return;
      }

      const objectResponseDeals = responseToObject(responseDeals);

      payloadSetHash = {
        ...payloadSetHash,
        paymentType: objectResponseDeals?.paymentType,
        proposals: objectResponseDeals?.proposals,
        lawyerId: responseCases.data.lawyerId,
        group: responseCases.data?.group
      };

      payloadFormProposal = {
        ...payloadFormProposal,
        authorCounterProposalText:
          objectResponseDeals.authorCounterProposalText,
        authorCounterProposalValue:
          objectResponseDeals.authorCounterProposalValue,
        initialAuthorCounterproposalValue: objectResponseDeals.authorCounterProposalValue,
        dealId: objectResponseDeals.id,
        dealStatus: objectResponseDeals.dealStatus,
        lastProposal: objectResponseDeals.lastProposal,
        selectedPaymentType: objectResponseDeals.selectedPaymentType,
      };
    }

    const promiseSetHash = dispatch({
      type: "SET_HASH",
      payload: payloadSetHash,
    });

    
    const responseFindConstants = await fetchFindConstants(data.dealStatus);
    
    const promiseFormProposal = dispatch({
      type: "FORM_PROPOSAL",
      payload: {
        ...payloadFormProposal,
        status: checkAcceptProposal(data.dealStatus) && responseFindConstants.status,
        dealStatus: checkAcceptProposal(data.dealStatus) && responseFindConstants.dealStatus,
      },
    });

    dispatch({
      type: 'ACCEPT_PROPOSAL', 
      payload: {
        acceptProposal: checkAcceptProposal(data.dealStatus)
      }})

    Promise.all([promiseFormProposal, promiseSetHash]).then(() => {
      data.dealStatus === dealStatusOptions.feitoContrapropostapeloAutor ? nextStep(1) : nextStep(3);
    });
  };

  return (
    <div className="p-5">
      <Title className="text-left">{"Proposta de Acordo."}</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Col} className={`px-0 pb-0`}>
          <LabelForm>
            Favor informar o Código Validador enviado por e-mail *
          </LabelForm>
          <Input
            disabled={dataDeals.internalId}
            {...register("codValidador")}
            type="text"
            placeholder="Ex: 34AAB34"
            name="codValidador"
          />
          <p>{errors.codValidador?.message}</p>
        </Form.Group>

        <Form.Group as={Col} className={`px-0 pb-0`}>
          <InputGroup className="mb-3">
            <LabelForm>Há interesse na realização do acordo? *</LabelForm>
            <Form.Check
              {...register("dealStatus")}
              className="mb-3"
              sm={12}
              type="radio"
              id="Sim, tenho interesse na proposta oferecida"
              label="Sim, tenho interesse na proposta oferecida"
              name="dealStatus"
              value={whatStatus({autoSendDraft, sendToAdvocart})}
            />

            <Form.Check
              {...register("dealStatus")}
              className="mb-3"
              sm={12}
              type="radio"
              id="Fazer contraproposta (ou outros motivos)"
              label="Fazer contraproposta (ou outros motivos)"
              name="dealStatus"
              value={dealStatusOptions.feitoContrapropostapeloAutor}
            />
            <Error>{errors.dealStatus?.message}</Error>
          </InputGroup>
        </Form.Group>

        <Button
          disabled={loading}
          background="#004D7D"
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3"
        >
          Continuar
        </Button>
      </form>
    </div>
  );
}

export default Index;
