import React from "react";

import icoMaos from "assets/img-maos-acordo.svg";
import { Container } from "./styles";

function AcordoFechado() {
  return (
    <Container className="p-5">
      <img src={icoMaos} alt="Acordo Fechado" />
      <p>
        Olá, somos do Acordo Fechado. Nesse caso já celebramos o acordo. Caso
        tenha acontecido algum problema, favor entrar em contato conosco nos
        canais abaixo. Desejamos uma boa semana.
      </p>

      <div>
        <p>contato@acordofechado.com.br</p>
        <a
          style={{ color: "#000", cursor: "pointer" }}
          href="https://api.whatsapp.com/send?phone=5521995308814&text=&source=&data=&app_absent="
        >
          55 21 99530-8814
        </a>
      </div>
    </Container>
  );
}

export default AcordoFechado;
