import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const Section = styled.div`
  background-color: ${({theme}) => theme.background};
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const CardAccordion = styled(Card)`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px !important;
  .card-header{
    cursor: pointer;
    height: 70px;
    background-color: ${({theme}) => theme.white} !important;
    color: ${({theme}) => theme.primary} !important;
    display: flex;
    align-items: center;
  }
  .card-body{
    color: ${({theme}) => theme.text} !important;
  }
`;

