import { Form } from "react-bootstrap";
import {NumericFormat} from "react-number-format";
import InputMask from 'react-input-mask';

import styled from "styled-components";

export const Input = styled(Form.Control)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({error, theme}) => error === 'true' ? theme.error : theme.black}; 

  &:focus {
    outline: none;
  }
`;

export const InputCustom = styled(NumericFormat)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({error, theme}) => error === 'true' ? theme.error : theme.black}; 
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const InputMaskCustom = styled(InputMask)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${({error, theme}) => error ? theme.error : theme.black}; 
  width: 100%;

  &:focus {
    outline: none;
  }
`; 