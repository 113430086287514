import React from 'react';
import { useSelector } from 'react-redux';

import Step1 from './step_1';
import Step2 from './step_2';
import Step3 from './step_3';
import Step4 from './step_4';
import Step5 from './step_5';
import Step6 from './step_6';
import { Title, DivSteps, IconStep, SubText } from './styles';

function Steps({ title, subTitle }) {
  
  const state = useSelector(state => state);

  const getStep = {
    1: () => <Step1 />,
    2: () => <Step2 />,
    3: () => <Step3 />,
    4: () => <Step4 />,
    5: () => <Step5 />,
    6: () => <Step6 />
  }

  const quantitySteps = Object.keys(getStep);
  return (
    <div>
      <Title className="text-center">{title}</Title>
      <DivSteps className="justify-content-center pt-3 mb-3">
        {quantitySteps.map((st) => (
          <IconStep 
            key={`step_${Number(st)}`} 
            show={state.stepForm + 1 === Number(st)} />
        ))}
      </DivSteps>

      <SubText className="text-center">{subTitle}</SubText>

      {getStep[state.stepForm + 1] && getStep[state.stepForm + 1]()}
    </div>
  );
}

export default Steps;