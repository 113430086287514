import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { Input, InputMaskCustom } from "../../../../../components/Atoms/input/styles";
import { LabelForm } from "../../../../../components/Atoms/LabelForm/styles";
import { Title } from "./styles";
import { Button } from "../styles";
import { validator } from "./validator";
import { deals, persons } from "../../../../../api/api";
import { Error } from "../../../../../components/Atoms/ErrorForm/styles";

function Index() {
  const { formProposal, dataDeals, stepFormOld, settings } = useSelector(
    (state) => state
  );
  const { loading } = settings;
  let { lawyerId } = dataDeals;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validator),
  });

  const dispatch = useDispatch();

  const backstep = () => {
    dispatch({ type: "STEP_FORM", payload: { stepForm: stepFormOld } });
  };

  const nextStep = (step) => {
    dispatch({ type: "SETTINGS", payload: { loading: false } });
    dispatch({ type: "STEP_FORM", payload: { stepForm: step } });
  };

  const onSubmit = async (data) => {
    dispatch({ type: "SETTINGS", payload: { loading: true } });
    function formatContactsPersonToUpdate(contactsPerson) {
      let hotPhone = Object.assign({}, contactsPerson);
      hotPhone = hotPhone.telephones;
      delete contactsPerson.telephones;

      return {
        ...contactsPerson,
        hotPhone: hotPhone,
        lawyerId,
      };
    }

    let payloadDeal = formProposal;

    if (formProposal.dealStatus === "Feito contraproposta pelo autor") {
      payloadDeal = { ...formProposal, selectedPaymentType: undefined };
    }

    let response = await persons.put(
      formatContactsPersonToUpdate(data)
    );
    response = await deals.quickUpdate(payloadDeal);

    if (response) {
      nextStep(5);
    } else {
      dispatch({
        type: "SETTINGS",
        payload: {
          modalAlert: {
            active: true,
            title: "Erro",
            description: "Ocorreu um erro, entre em contato com o suporte",
          },
          loading: false,
        },
      });
    }
  };

  return (
    <div className="p-5">
      <Title className="text-left">Confirme seus dados de contato</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Col} className={`px-0 pb-0`}>
          <InputGroup className="mb-3">
            <LabelForm>
              Favor confirmar seu e-mail abaixo para envio das demais etapas da
              negociação:
            </LabelForm>
            <Input
              {...register("email")}
              type="text"
              placeholder="email@dominio.com.br"
              name="email"
            />
          </InputGroup>
          <Error>{errors.email?.message}</Error>
        </Form.Group>

        <Form.Group as={Col} className={`px-0 pb-0`}>
          <InputGroup className="mb-3">
            <LabelForm>
              Favor confirmar seu telefone com whatsapp abaixo para envio das demais etapas
              da negociação:
            </LabelForm>
            <Controller
              {...register("telephones")}
              control={control}
              name="telephones"
              render={({ field }) => (
                <InputMaskCustom mask="(99) 99999-9999" {...field} />
              )}
            />
          </InputGroup>
          <Error>{errors.telephones?.message}</Error>
        </Form.Group>

        <Button
          disabled={loading}
          type="button"
          onClick={backstep}
          background={"#808080"}
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3"
        >
          Voltar
        </Button>

        <Button
          disabled={loading}
          background="#004D7D"
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3"
        >
          Continuar
        </Button>
      </form>
    </div>
  );
}

export default Index;
