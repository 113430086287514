import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { LabelForm } from '../../../../../components/Atoms/LabelForm/styles';
import { Title } from './styles';
import { Button } from '../styles';
import { validator } from './validator';
import { deals } from '../../../../../api/api';
import { Error } from '../../../../../components/Atoms/ErrorForm/styles';
import { checkAcceptProposal, dealStatusOptions, whatStatus } from '../../../../../utils/dealStatus';

function Index() {
  const dispatch = useDispatch();

  const { stepFormOld, formProposal, settings } = useSelector(state => state);
  const { autoSendDraft, loading, sendToAdvocart } = settings;
  
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validator)
  });


  const handleBackStep = () => {
    dispatch({ type: 'STEP_FORM', payload: { stepForm: stepFormOld } })
  }


  const nextStep = (step) => {
    dispatch({type: 'SETTINGS', payload: { loading: false }})
    dispatch({ type: 'STEP_FORM', payload: { stepFormOld: 2, stepForm: step } })
  }


  const onSubmit = async ({ dealStatus }) => {
    const PENDENTE_ESCRITORIO_VALIDAR_MINUTA_ID = '19';
    dispatch({type: 'SETTINGS', payload: { loading: true }})
    const respConstants = await deals.findConstants(`?id=${dealStatus}`);

    const dataDealsStatus = checkAcceptProposal(dealStatus)? {
      'dealStatus':  respConstants.data[0].dealStatus,
      'status': respConstants.data[0].status,
      'oldDealStatus': formProposal.dealStatus,
      'oldStatus': formProposal.status,
    } : {};

    if ( dealStatus === PENDENTE_ESCRITORIO_VALIDAR_MINUTA_ID ) {
      dispatch({
        type: 'FORM_PROPOSAL',
        payload: {
          ...formProposal,
          ...dataDealsStatus,
          authorCounterProposalValue: formProposal.initialAuthorCounterproposalValue,
        }
      })
    }else {
      dispatch({
        type: 'FORM_PROPOSAL',
        payload: {
          ...formProposal,
          ...dataDealsStatus
        }
      })
    }

    dispatch({
      type: 'ACCEPT_PROPOSAL', 
      payload: {
        acceptProposal: checkAcceptProposal(dealStatus)
      }})

    if (dealStatus ===  dealStatusOptions.feitoContrapropostapeloAutor) {
      return nextStep(4);
    }
    
    return nextStep(3)
  };

  return (
    <div className="p-5">
      <Title className="text-left">Infelizmente, o valor oferecido inicialmente já é a alçada máxima da Empresa...!</Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Col} className={`px-0 pb-0`}>
          <LabelForm>Sendo assim, o que gostaria de fazer?</LabelForm>
          <Form.Check
            {...register('dealStatus')}
            className="mb-3"
            sm={12}
            type="radio"
            id="Aceito a proposta enviada inicialmente"
            label="Aceito a proposta enviada inicialmente"
            name="dealStatus"
            value={whatStatus({autoSendDraft, sendToAdvocart})} />

          <Form.Check
            {...register('dealStatus')}
            className="mb-3"
            sm={12}
            type="radio"
            id="Não aceito o acordo"
            label="Fazer contraproposta"
            name="dealStatus"
            value={dealStatusOptions.feitoContrapropostapeloAutor} />
          <Error>{errors.dealStatus?.message}</Error>
        </Form.Group>

        <Button
          disabled={loading}
          type='button'
          onClick={handleBackStep}
          background={"#808080"}
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3">Voltar</Button>

        <Button
          disabled={loading}
          background="#004D7D"
          color="#FFFFFF"
          className="d-block order-1 w-100 mb-3">Continuar</Button>
      </form>
    </div>
  );
}

export default Index;