import React from "react";
import { ProfileProvider } from "./ProfileContext";
import { BootRuleProvider } from "./BootRuleContext";

const AppProvider = ({ children }) => {
  return (
    <ProfileProvider>
      <BootRuleProvider>{children}</BootRuleProvider>
    </ProfileProvider>
  );
};

export default AppProvider;
