export const dealStatusOptions = {
    acordoFechadoPendenteEscritorioValidarMinuta: "19",
    acordoFechadoEnviarMinutaParaAdvogado: "17",
    AcordoFechadoAguardandoDadosDeContaDoAutor: "43",
    feitoContrapropostapeloAutor: "8",
    enviarEmailDePropostaMajorada: "9",
    semAcordoAusenciDeInteresse: "22",
    temInteresseEmNegociar: "48",
  };

export const dealStatusOptionName = {
    feitoContrapropostapeloAutor: 'Feito contraproposta pelo autor',
}

export function whatStatus ({sendToAdvocart, autoSendDraft}) {
    // if (sendToAdvocart) {
    //     return dealStatusOptions.AcordoFechadoAguardandoDadosDeContaDoAutor
    // }

    if (autoSendDraft) {
        return dealStatusOptions.acordoFechadoEnviarMinutaParaAdvogado
    }

    return dealStatusOptions.acordoFechadoPendenteEscritorioValidarMinuta;
};

export function checkAcceptProposal(dealStatus) {
    return [dealStatusOptions.acordoFechadoEnviarMinutaParaAdvogado, dealStatusOptions.AcordoFechadoAguardandoDadosDeContaDoAutor, dealStatusOptions.acordoFechadoPendenteEscritorioValidarMinuta].includes(dealStatus)
}