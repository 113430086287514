import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({theme}) => theme.backgroundFooter};
  padding: 1.5rem;
  p{
    font-weight: 300;
    color:  ${({theme}) => theme.black};
    margin-bottom: 0;
  }
`;

export const Text2 = styled.p`
  font-weight: 500 !important;
`;