import styled from "styled-components";

export const Title = styled.h2`
  color: ${({theme}) => theme.black};
  font-size: 1rem;
  font-weight: bold;
`;


export const SubText = styled.p`
  color: ${({theme}) => theme.black};
  font-size: 1rem;
`;


export const FooterSucess = styled.div`
  background: ${({theme}) => theme.backgroundFooter};
  border-radius: 4px;
`;
