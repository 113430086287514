import React from 'react';
import FormProposal from './proposal';
import bgForm from 'assets/images/bg-form.png';
import { CardForm, Section } from './styles';
import Interest from './interest';

function _form({typeForm}) {

  const getForm = {
    interest: () => <Interest />,
    proposal: () => <FormProposal />
  }

  return (
    <Section id="form" className="pt-5 pb-0 p-sm-5" background={bgForm}>
      <CardForm className="mt-3 my-sm-3 px-0">
        {getForm[typeForm] ? getForm[typeForm](): ''}
      </CardForm>
    </Section>
  );
}

export default _form;