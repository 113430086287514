import { legacy_createStore as createStore } from 'redux'
let [,path] = window.location.pathname.split('/')

const INITIAL_STATE = {
  stepForm: 0,
  stepFormOld: 0,
  qtyStepForm: 0,
  autoSendDraft: false,
  interesse: '',
  typeForm: 'interest',
  modalHowWorks: !path === 'proposal' ? false : true,
  dataDeals: {
    hashValid: false,
    hash: null,
    lawyerId: null,
    externalId: null,
    dealId: null,
    lastProposal: 0,
    paymentType: '',
    proposals: []
  },
  
  formProposal:{
    dealStatus: '',
  },
  acceptProposal: false,
  formInterest: {},
  settings: {
    modalAlert: {
      title: '',
      description: '',
      active: false
    },
    loading: false,
    autoSendDraft: false,
  }
};

function form(state = INITIAL_STATE, action){
  switch (action.type) {
    case 'ACCEPT_PROPOSAL': {
      return {...state, acceptProposal: action.payload.acceptProposal}
    }
    case 'STEP_FORM':
      const { stepForm, qtyStepForm, stepFormOld} = action.payload
    
      return { ...state, qtyStepForm, stepForm, stepFormOld }
    
    case 'HOW_WORKS':
      const { modalHowWorks } = action.payload;
    
      return {...state, modalHowWorks }
    
    case 'SET_HASH':
      return {...state, dataDeals:{...state.dataDeals, ...action.payload}}

    case "SET_INTERESSE":
      const {interesse} = action.payload
    
      return {...state, interesse }
    
    case 'TYPE_FORM':
      const { typeForm } = action.payload
      
      return { ...state, typeForm }
    
    case 'FORM_PROPOSAL':{
      return { 
        ...state, 
        formProposal:{...state.formProposal, ...action.payload}}}
      
    case 'FORM_INTEREST':
        return { ...state, formInterest:{...action.payload}}

    case 'SETTINGS' : 
      return {...state, settings: {...state.settings, ...action.payload } }
    
    case 'SET_AUTO_SEND_DRAFT':
      return {...state, autoSendDraft: action.payload};
      
    default:
      return state;
  }
}


const store = createStore(form);

export default store;