import styled from 'styled-components';

export const WhatsDesk = styled.img`
    position: none;
    z-index: 1000;
    right: 0;
    top: 50%;
    @media(min-width: 990px){
      position: fixed;
    }
`;

export const WhatsMobile = styled.img`
    position: fixed;
    z-index: 1000;
    right: 15px;
    bottom: 12px;
    width: 4rem;
    @media(min-width: 990px){
      position: none;
      display:none;
    }
`;