import React from 'react';

import icoMaos from '../../assets/img-maos-acordo.svg';
import { Container } from './styles';

function LoadingCase() {
  return (
      <Container className="p-5">
          <img src={icoMaos} alt="Acordo Fechado" />
          <p>
              Aguarde! Estamos carregando os dados.</p>

      </Container>
  );
}

export default LoadingCase;