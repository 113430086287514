import styled, {createGlobalStyle} from 'styled-components'
import { Nav, Navbar } from 'react-bootstrap'
import Scrollchor from 'react-scrollchor';

export const Sociais = styled(Nav)`
  @media(min-width: 1100px){
    display: flex !important;
  }
`;

export const Link = styled(Scrollchor)`
  color: ${({theme}) => theme.white} !important;
  background-color: ${({theme}) => theme.primary} !important;
  border-radius: 42px;
  width: 140px;
  text-align: center;
`;

export const HeaderNav = styled(Navbar)`
  z-index: 99999;
  position: relative;
  width: 100% ;
  background: ${({theme}) => theme.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

`;

export default createGlobalStyle`
  .navbar-sticky{
    position: fixed;
    animation: moveDown 0.5s ease-in-out !important;
  }
  @keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}
`;